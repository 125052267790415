import React from 'react';
import {Layout} from 'components/pageLayouts';

const missing = () => (
	<Layout>
		<h2>Oops!</h2>
	</Layout>
);

export default missing;
